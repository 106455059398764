import React from 'react';
import './Home.scss';

function Home()
{
    return(
        <div className='wrapper'>
            <h2>This is my website!</h2>
            <p>I proudly welcome into the world this poorly designed mess</p>
            <p>I am currently in the process of remaking this site!<br/> please hang on as it goes through the works!</p>
        </div>
    )
}


export default Home;
