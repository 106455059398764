import React from 'react';
import './Header.scss';

let headerClass: string = "none";
function Link(name: string, link: string)
{
    return(
        <a href={link}>{name}</a>
    )
}
function Header({slogan,items}: {slogan: string,items: string[][]})
{
    return(
        <header className={headerClass}>
            <a href = "/">
                <div className='logo'>
                    <h1>Thomas Swarbrick</h1>
                    <h6>{slogan}</h6>
                </div>
            </a>
            <ul className='menu'>
                {items.map(x => <li> {Link(x[0],x[1])} </li>)}
            </ul>
        </header>
    );
}

export default Header;
