import React from 'react';
import './Footer.scss';
function Footer()
{
    return(
        <footer>
            <h6>Copyright - Thomas Swarbrick 2023</h6>
        </footer>
    );
}

export default Footer;