import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Header from './Global/Header';
import Home from './Pages/Home'
import Footer from './Global/Footer';
import reportWebVitals from './reportWebVitals';
import TemplatePage from './Pages/Page';
import NoPage from './Pages/NoPage';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const pages: string[][]= [
  ["Blog","/blog"]]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Header 
      slogan="totally irrelevant since the 30th of November 2022"
      items={pages}
      />
      <main>
          <Routes>
            <Route path="/" element={<Home/>}/>
            {pages.map(item => <Route path={item[1]} element={<TemplatePage name={item[0]}/>}/>)}
          </Routes>
        </main>
      <Footer/>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
