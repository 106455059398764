import React from 'react';
import './Page.scss';

function TemplatePage({name} : {name: string})
{
    return(
        <div className='wrapper'>
        <h2>{name}</h2>
    </div>
    )
}


export default TemplatePage;